// Core
import React from "react";
// Components
import Layout from "../layout/";

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout
        seo={{
          pathname: "/404",
          title: "404: Not found",
        }}
      >
        <div className="aic flex jcc minh100vh">
          <div className="tac">
            <h1>PAGE NOT FOUND</h1>

            <a
              className="button"
              href="/"
            >
              Return Home
            </a>
          </div>
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage;
